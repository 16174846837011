export const usePhoneUpdateAction = (phoneValue: string) => {
  const customerStore = useCustomerStore()

  const userHasCustomPhoneField = computed(() => {
    return !!customerStore.customer?.custom?.fields?.phone
  })
  // depending on the user custom fields, we need to update the phone number
  // https://kb.snowdog.dev/link/1658#bkmrk-phone-number
  if (!customerStore.customer) {
    return {}
  }
  if (userHasCustomPhoneField.value || customerStore.isCreator) {
    return reactive({
      action: 'setCustomField',
      name: 'phone',
      value: phoneValue,
    })
  }
  return reactive({
    action: 'setCustomType',
    type: {
      key: 'customer_creator',
      typeId: 'type',
    },
    fields: {
      phone: phoneValue,
    },
  })
}
