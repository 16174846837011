import { skipHydrate } from 'pinia'
import { useForm } from 'vee-validate'
import { paymentsSchema } from 'account/validation-schema/creator'
import type { CustomAction, MixedAction } from 'account/types/actions'

export const usePaymentStore = defineStore('creatorPaymentStore', () => {
  const isProcessing = ref(false)
  const form = useForm({ validationSchema: paymentsSchema })
  const [phone] = form.useFieldModel(['phone'])
  const [email] = form.useFieldModel(['email'])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)

  const updatePaymentsStep = async () => {
    const customerStore = useCustomerStore()

    return await customerStore.updateAccount([
      {
        action: 'setCustomField',
        name: 'paypal_email',
        value: email.value as string,
      },
      usePhoneUpdateAction(phone.value as string) as CustomAction | MixedAction,
    ])
  }

  const submitForm = async () => {
    isProcessing.value = true
    const { $notify } = useNuxtApp()

    const isDataSent = await updatePaymentsStep()

    if (!isDataSent) {
      isProcessing.value = false
      return
    }

    const creatorStore = useCreatorStore()
    if (!creatorStore.isPublished) {
      const isCreatorPublished = await creatorStore.publishProfile()
      if (isCreatorPublished) {
        $notify('Creator created and published successfully')
        await navigateTo('/create-collab/new/description')
      }
    } else {
      $notify('Payment information updated')
    }

    isProcessing.value = false
  }

  return {
    email,
    form,
    isFormSubmitted,
    isProcessing: skipHydrate(isProcessing),
    phone,
    submitForm,
  }
})
